/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

import AjouterPanier from "../components/ajouterPanier"
import SEO from "./../components/seo"

import Layout from "../components/layout"
import { useDispatch, useSelector } from "react-redux"
import { setPpMargeG, setPpMargeD, setPpMargeH, setPpMargeB, setPpInterH, setPpInterL, setPpOuvertures, setPpType } from "../actions/passe"
import { getPrix } from "../services/panier"
import { options_pp, bordureMin } from "../services/prixBaguettes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointRight, faUndoAlt } from "@fortawesome/free-solid-svg-icons"
import Pulse from "react-reveal/Pulse"
import { setDimensions } from "../actions"
import { Link } from "gatsby-plugin-spinner"
import { PrevisuPasseSeul } from "@bko/previsus"

const PassePartoutProductPage = props => {
  const [apertures, setApertures] = useState(null)
  const urlParams = new URLSearchParams(typeof window === "undefined" ? "" : window.location.search)
  const [showApertureChoice, setShowApertureChoice] = useState(urlParams.has("type") && urlParams.get("type") === "multivues")
  const [showDimensions, setShowDimensions] = useState(false)
  const [dimensionsSet, setDimensionsSet] = useState(false)
  const [margesSet, setMargesSet] = useState(false)
  const [showSubmitDim, setShowSubmitDim] = useState(true)
  const [displayErrorDim, setDisplayErrorDim] = useState(false)
  const aspectProduit = useSelector(s => s.aspectProduit)

  const pp = props.pageContext.passePartout
  const largeurInput = useRef()
  const pageProduit = useSelector(state => state.PageProduit)

  const set1Ouverture = () => {
    setApertures(1)
    setDimSaisies({ ...dimensionsDefaut, hauteur: "", largeur: "", largeurExt: "", hauteurExt: "" })
    setShowDimensions(true)
    //dispatch(setShowEditMarges(false))
    setShowApertureChoice(false)
    dispatch(setPpOuvertures([1, 1], "passe"))
    setDimensionsSet(false)
    setMargesSet(false)
    setDisplayErrorDim(false)
  }

  useEffect(() => {
    if (urlParams.has("type") && urlParams.get("type") === "1ouverture") {
      set1Ouverture()
    }
  }, [urlParams.get("type")])

  const dimensionsDefaut = {
    hauteur: 40,
    largeur: 30,
    hauteurExt: 50,
    largeurExt: 40,
    g: 5,
    d: 5,
    h: 5,
    b: 5,
    interL: 5,
    interH: 5,
    default: true,
  }
  const reset = () => {
    dispatch(setPpType(null))
    setDimSaisies({ ...dimensionsDefaut, hauteur: "", largeur: "", largeurExt: "", hauteurExt: "" })
    setApertures(null)
    setShowDimensions(false)
    setShowApertureChoice(false)
    // dispatch(resetPageProduct())
    setDimensionsSet(false)
    setMargesSet(false)
    setDisplayErrorDim(false)
  }
  const [dimSaisies, setDimSaisies] = useState({ ...dimensionsDefaut, hauteur: "", largeur: "", largeurExt: "", hauteurExt: "" })
  const dispatch = useDispatch()
  const chooseNbPic = oPp => {
    dispatch(setPpOuvertures(oPp, "passe"))
    setShowDimensions(true)
  }

  useEffect(() => {
    largeurInput?.current?.focus()
  }, [pageProduit.pp.ouvertures[0], pageProduit.pp.ouvertures[1]])

  useEffect(() => {
    dispatch(setDimensions(parseFloat(dimensionsDefaut.largeur), parseFloat(dimensionsDefaut.hauteur)))

    dispatch(setPpMargeG(5))
    dispatch(setPpMargeD(5))
    dispatch(setPpMargeH(5))
    dispatch(setPpMargeB(5))
  }, [pageProduit.pp.type, pageProduit.dimensionsDefault])
  useEffect(() => {
    dispatch(setDimensions(parseFloat(dimensionsDefaut.largeur), parseFloat(dimensionsDefaut.hauteur)))
    dispatch(setPpMargeG(5))
    dispatch(setPpMargeD(5))
    dispatch(setPpMargeH(5))
    dispatch(setPpMargeB(5))
    //dispatch(resetPageProduct())
  }, [])
  const passe = pageProduit.pp

  const handleUpdate = event => {
    event.preventDefault()
    let dim
    if (dimSaisies[event.target.name] !== event.target.value && dimSaisies[event.target.name] !== parseFloat(event.target.value)) {
      if (isNaN(parseFloat(event.target.value.replace(",", ".")))) {
        dim = { ...dimSaisies, [event.target.name]: event.target.value.replace(",", "."), default: false }
        setDimSaisies(dim)
        return true
      } else {
        dim = { ...dimSaisies, [event.target.name]: parseFloat(event.target.value.replace(",", ".")), default: false }
      }
      if (pageProduit.pp.type === "simple") {
        switch (event.target.name) {
          case "h":
            dim = { ...dim, b: parseFloat((dim.hauteurExt - (dim.h + dim.hauteur * passe.ouvertures[1] + dim.interH * (passe.ouvertures[1] - 1))).toFixed(1)) }
            break
          case "d":
            dim = { ...dim, g: parseFloat((dim.largeurExt - (dim.d + dim.largeur * passe.ouvertures[0] + dim.interL * (passe.ouvertures[0] - 1))).toFixed(1)) }
            break
          case "g":
            dim = { ...dim, d: parseFloat((dim.largeurExt - (dim.g + dim.largeur * passe.ouvertures[0] + dim.interL * (passe.ouvertures[0] - 1))).toFixed(1)) }
            break
          case "b":
            dim = { ...dim, h: parseFloat((dim.hauteurExt - (dim.b + dim.hauteur * passe.ouvertures[1] + dim.interH * (passe.ouvertures[1] - 1))).toFixed(1)) }
            break
          case "interH":
            dim = {
              ...dim,
              g: parseFloat(((dim.largeurExt - (dim.largeur * passe.ouvertures[0] + dim.interH * (passe.ouvertures[0] - 1))) / 2).toFixed(1)),
              d: parseFloat(((dim.largeurExt - (dim.largeur * passe.ouvertures[0] + dim.interH * (passe.ouvertures[0] - 1))) / 2).toFixed(1)),
            }
            break
          case "interL":
            dim = {
              ...dim,
              h: parseFloat(((dim.hauteurExt - (dim.hauteur * passe.ouvertures[1] + dim.interL * (passe.ouvertures[1] - 1))) / 2).toFixed(1)),
              b: parseFloat(((dim.hauteurExt - (dim.hauteur * passe.ouvertures[1] + dim.interL * (passe.ouvertures[1] - 1))) / 2).toFixed(1)),
            }
            break
        }
      }
      if (dim.g >= bordureMin && dim.g < 150) {
        dispatch(setPpMargeG(parseFloat(dim.g)))
      }
      if (dim.d >= bordureMin && dim.d < 150) {
        dispatch(setPpMargeD(parseFloat(dim.d)))
      }
      if (dim.h >= bordureMin && dim.h < 150) {
        dispatch(setPpMargeH(parseFloat(dim.h)))
      }
      if (dim.b >= bordureMin && dim.b < 150) {
        dispatch(setPpMargeB(parseFloat(dim.b)))
      }

      if (dim.interL >= bordureMin) {
        dispatch(setPpInterH(parseFloat(dim.interL)))
      }
      if (dim.interH >= bordureMin) {
        dispatch(setPpInterL(parseFloat(dim.interH)))
      }
      setMargesSet(false)
      setDimSaisies(dim)
    }
  }

  const hauteurMax = parseInt(pp.gm !== null ? pp.gm.pa_plaque_longueur : pp.pa_plaque_longueur)
  const largeurMax = parseInt(pp.gm !== null ? pp.gm.pa_plaque_largeur : pp.pa_plaque_largeur)
  const largeurMaxFen = (largeurMax - bordureMin * (passe.ouvertures[1] + 1)) / passe.ouvertures[1]
  const hauteurMaxFen = (hauteurMax - bordureMin * (passe.ouvertures[0] + 1)) / passe.ouvertures[0]

  const handleSubmit = event => {
    setShowSubmitDim(false)
    setMargesSet(false)
    const data = new FormData(event.target)
    event.preventDefault()

    let dim = {
      ...dimSaisies,
      largeur: parseFloat(data.get("largeur")),
      hauteur: parseFloat(data.get("hauteur")),
      default: false,
    }

    setDimSaisies(dim)
    if (pageProduit.pp.type === "multivues") {
      if (
        Math.max(dim.largeur, dim.hauteur) > Math.max(largeurMaxFen, hauteurMaxFen) ||
        Math.min(dim.largeur, dim.hauteur) > Math.min(largeurMaxFen, hauteurMaxFen)
      ) {
        setDisplayErrorDim(true)
        return false
      }
    }

    if (pageProduit.pp.type === "simple") {
      dim = {
        ...dim,
        largeurExt: parseFloat(data.get("largeurExt")),
        hauteurExt: parseFloat(data.get("hauteurExt")),
      }

      let hb = dim.hauteurExt - dim.hauteur * passe.ouvertures[1] - dim.interH * (passe.ouvertures[1] - 1)
      let gd = dim.largeurExt - dim.largeur * passe.ouvertures[0] - dim.interL * (passe.ouvertures[0] - 1)
      dim = {
        ...dim,
        h: parseFloat((hb / 2).toFixed(2)),
        b: parseFloat((hb / 2).toFixed(2)),
        g: parseFloat((gd / 2).toFixed(2)),
        d: parseFloat((gd / 2).toFixed(2)),
      }
      setDimSaisies(dim)
      dispatch(setPpMargeG(parseFloat(dim.g)))
      dispatch(setPpMargeD(parseFloat(dim.d)))
      dispatch(setPpMargeH(parseFloat(dim.h)))
      dispatch(setPpMargeB(parseFloat(dim.b)))
    }

    setDimensionsSet(false)
    if (pageProduit.pp.type === "simple") {
      setDisplayErrorDim(null)
    } else {
      setDimensionsSet(true)
      dispatch(setDimensions(parseFloat(dim.largeur), parseFloat(dim.hauteur), false, "passe"))
      //dispatch(setShowEditMarges(true))
    }
    if (pageProduit.pp.type === "simple") {
      if (
        (dim.hauteur >= 1 || dim.hauteur === 0) &&
        (dim.largeur >= 1 || dim.largeur === 0) &&
        Math.max(dim.hauteur, dim.largeur) <= Math.max(largeurMaxFen, hauteurMaxFen) &&
        Math.min(dim.hauteur, dim.largeur) <= Math.min(largeurMaxFen, hauteurMaxFen) &&
        (pageProduit.pp.type === "multivues" ||
          (dim.hauteur + bordureMin * 2 <= dim.hauteurExt &&
            dim.largeur + bordureMin * 2 <= dim.largeurExt &&
            Math.max(dim.hauteurExt, dim.largeurExt) <= Math.max(largeurMax, hauteurMax) &&
            Math.min(dim.hauteurExt, dim.largeurExt) <= Math.min(largeurMax, hauteurMax)))
      ) {
        setDimensionsSet(true)
        dispatch(setDimensions(parseFloat(dim.largeur), parseFloat(dim.hauteur), false, "passe"))
        // dispatch(setShowEditMarges(true))
      } else {
        setShowSubmitDim(true)

        setDisplayErrorDim(true)
        //dispatch(setShowEditMarges(false))
      }
    }
  }

  let hidden = false
  if (
    (((dimSaisies.hauteur + bordureMin * 2 > dimSaisies.hauteurExt || dimSaisies.largeur + bordureMin * 2 > dimSaisies.largeurExt) &&
      pageProduit.pp.type === "simple") ||
      parseFloat(dimSaisies.h) != pageProduit.pp.margeH ||
      parseFloat(dimSaisies.b) != pageProduit.pp.margeB ||
      parseFloat(dimSaisies.g) != pageProduit.pp.margeG ||
      parseFloat(dimSaisies.d) != pageProduit.pp.margeD) &&
    !dimSaisies.default
  ) {
    hidden = true
  }
  let d = new Date()
  const bg = parseInt(pp.pa_passe_hexa.replace("#", ""), 16) < parseInt("cccccc", 16) ? "#fbf8f1" : "#9f8f7e"

  const largeur = dimSaisies.largeur * passe.ouvertures[0] + dimSaisies.interH * (passe.ouvertures[0] - 1) + dimSaisies.g + dimSaisies.d
  const hauteur = dimSaisies.hauteur * passe.ouvertures[1] + dimSaisies.interL * (passe.ouvertures[1] - 1) + dimSaisies.h + dimSaisies.b
  const pa = props.pageContext.pa.map(e => e.node)
  return (
    <>
      <Layout banner={false} location={props.location}>
        <SEO
          title={pp.pa_libelle}
          description="Passe-partout sur mesure, découpe numérique, selon vos marges et nombre de fenêtres"
          jsonLd={[
            {
              "@context": "http://schema.org",
              "@type": "Product",
              sku: pp.pa_reference,
              mpn: "PP-" + pp.pa_reference,
              identifier: pp.pa_reference,
              description:
                "Passe-partout Encadrement sur mesure : Le placement d’un passe-partout est fortement conseillé pour l’encadrement d’aquarelles ou de pastels ainsi que toute autre œuvre sur papier sous verre (eau-forte, sérigraphie, dessin, etc.).",
              name: pp.pa_libelle,
              image: pp.pa_image,
              url: typeof window !== "undefined" ? window.location.href : "",
              brand: {
                "@type": "Brand",
                name: "Encadrement sur mesure",
              },
              offers: {
                "@type": "Offer",
                availability: "http://schema.org/InStock",
                price: getPrix(pp, pageProduit, pa, null),
                url: typeof window !== "undefined" ? window.location.href : "",
                priceCurrency: "EUR",
                priceValidUntil: d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2),
              },
              itemCondition: "https://schema.org/NewCondition",
            },
          ]}
        />
        <h1>{pp.pa_libelle.replace("+ Passe-partout", "+")}</h1>
        <div className="columns is-multiline mb-5">
          <div className="column is-12-tablet is-6-widescreen ">
            <span>
              Réf.{" "}
              <strong>
                {pp.pa_reference}
                {pp.gm !== null && (dimSaisies.hauteur >= pp.pa_plaque_longueur || dimSaisies.largeur >= pp.pa_plaque_hauteur ? "gm" : "")}
              </strong>
            </span>
            <div style={{ background: bg, position: "sticky", top: 100 }} className="pt-5">
              <PrevisuPasseSeul
                showDimensions={dimensionsSet}
                product={pp}
                aspectProduit={aspectProduit}
                couleurInterieur={bg}
                pageProduit={pageProduit}
                dimSaisies={dimSaisies}
                handleUpdate={handleUpdate}
                size={400}
              />
            </div>
          </div>

          <div className="column is-12-tablet is-6-widescreen article">
            {pageProduit.pp.type !== null && (
              <div className="is-flex is-flex-direction-row-reverse">
                <button
                  className="button is-secondary is-small is-warning"
                  onClick={() => {
                    reset()
                  }}
                  data-cy="reinit-config-passe-product"
                >
                  <FontAwesomeIcon icon={faUndoAlt} className="mr-1" />
                  Réinitialiser la configuration du passe partout
                </button>
              </div>
            )}
            <div className="columns is-centered">
              <fieldset
                className="mt-4 column is-narrow"
                style={{
                  border: "2px solid #FFDC4A",
                  padding: "1rem 2rem",
                }}
              >
                <legend
                  style={{
                    textAlign: "left",
                    marginLeft: 5,
                    padding: 10,
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    color: "#666",
                  }}
                >
                  Commander un échantillon de passe-partout
                </legend>
                <div className="columns">
                  <div className="column is-narrow" style={{ background: bg }}>
                    <PrevisuPasseSeul
                      product={pp}
                      couleurInterieur={bg}
                      pageProduit={{
                        largeur: 20,
                        hauteur: 20,
                        pp: { ouvertures: [1, 1], type: "simple", margeH: 5, margeB: 5, margeD: 5, margeG: 5, interL: 0, interH: 0 },
                      }}
                      size={80}
                    />
                  </div>
                  <div className="column">
                    <p style={{ fontSize: "1em", color: "#888", margin: 0 }}>
                      10x10 cm (fenêtre image : 5x5cm) - Tarif :{" "}
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(pp.prixEchantillon)}{" "}
                      TTC
                      <br />
                      Frais de ports <b>offerts</b> !
                    </p>
                    <AjouterPanier produits_annexes={pa} product_selected={{ ...pp, type: "passe_partout" }} echantillon={true} />
                  </div>
                </div>
              </fieldset>
            </div>

            <div className="columns is-multiline is-vcentered">
              <h2>
                {apertures === null && !showApertureChoice && <FontAwesomeIcon className="cursor" size="xs" icon={faHandPointRight} />}Je choisis le type de
                passe partout
              </h2>
              <div>
                <div className="">
                  <div
                    role="radio"
                    aria-checked={apertures === 1}
                    className={`button ${apertures === 1 ? "selected" : ""}`}
                    onKeyPress={() => {}}
                    tabIndex={0}
                    onClick={() => {
                      set1Ouverture()
                    }}
                  >
                    <span>1&nbsp;ouverture</span>
                  </div>
                  <div
                    role="radio"
                    aria-checked={showApertureChoice}
                    className={"button " + (showApertureChoice ? "selected" : "")}
                    onKeyPress={() => {}}
                    tabIndex={0}
                    onClick={() => {
                      setShowApertureChoice(true)
                      setDimSaisies({ ...dimensionsDefaut, hauteur: "", largeur: "", largeurExt: "", hauteurExt: "" })
                      setApertures(null)
                      //dispatch(setShowEditMarges(false))
                      setShowDimensions(false)
                      setDimensionsSet(false)
                      setMargesSet(false)
                      setDisplayErrorDim(false)
                    }}
                  >
                    <span>Multivues</span>
                  </div>
                </div>
              </div>
            </div>

            {showApertureChoice && (
              <>
                Dimensions extérieures maximales du passe partout : {largeurMax} x {hauteurMax} cm Déduisez au moins 2cm de marges de chaque côté et entre les
                fenêtres
                <div className="columns is-multiline is-vcentered">
                  <h2>
                    {apertures === null && <FontAwesomeIcon className="cursor" icon={faHandPointRight} />}Je choisis le nombre d’ouvertures de mon passe partout
                  </h2>
                  <div>
                    {[...Array(5).keys()].map(i => (
                      <div
                        key={i}
                        className={`button ml-1 mr-1 ${apertures == i + 2 ? "is-primary" : ""}`}
                        role="radio"
                        style={{ cursor: "pointer" }}
                        onKeyPress={() => {}}
                        tabIndex={0}
                        aria-checked={apertures == i + 2}
                        onClick={() => setApertures(i + 2)}
                      >
                        {i + 2} fenêtres
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}

            {showApertureChoice && apertures >= 1 && (
              <>
                <h2>
                  {apertures !== null && !showDimensions && <FontAwesomeIcon className="cursor" icon={faHandPointRight} />}Je choisis la présentation des
                  fenêtres
                </h2>
                {[[1, 1], ...options_pp].map(oPp =>
                  oPp[0] * oPp[1] === apertures ? (
                    <div
                      key={`${oPp[0]}-${oPp[1]}`}
                      style={{ display: "inline-block" }}
                      className={`opacityChooser opacityChooserRed disableOutline has-text-centered ${
                        oPp[0] === pageProduit.pp.ouvertures[0] && oPp[1] === pageProduit.pp.ouvertures[1] ? "active" : ""
                      }`}
                      onClick={() => chooseNbPic(oPp)}
                      role="button"
                      onKeyPress={() => {}}
                      tabIndex={0}
                    >
                      <div className="tirage_multivues" style={{ marginRight: 7, marginLeft: 7 }}>
                        {Array.from({ length: oPp[1] }, (v, i) => i).map(krow => (
                          <div key={krow}>
                            {Array.from(Array(oPp[0])).map((col, kcol) => (
                              <div key={`${krow}-${kcol}`} className="case">
                                <div
                                  className="fakePic"
                                  style={{ background: ["blue", "red", "green", "orange", "purple", "CornflowerBlue"][apertures], width: 35, height: 35 }}
                                >
                                  {krow * oPp[0] + (kcol + 1)}
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>{" "}
                      <br />
                      {oPp[0] === pageProduit.pp.ouvertures[0] && oPp[1] === pageProduit.pp.ouvertures[1] ? (
                        "Choisi"
                      ) : (
                        <button className="button is-small" onClick={() => chooseNbPic(oPp)} data-cy="choisir-passe-product">
                          Choisir
                        </button>
                      )}
                    </div>
                  ) : null
                )}
              </>
            )}

            {showDimensions && (
              <>
                <h2>Je renseigne les dimensions</h2>
                <span style={{ textAlign: "justify", marginBottom: "30px", fontStyle: "italic" }}>
                  Prévoir le recouvrement du passe-partout sur la photo pour éviter qu&rsquo;elle ne passe à travers. Exemple :{" "}
                  <b> Pour une photo de dimensions 30.5 x 40.5 cm, indiquez 30 x 40 pour une mise en place aisée de votre sujet</b>
                </span>
                <form onSubmit={handleSubmit}>
                  <div className="columns">
                    <div className="column is-narrow">
                      <Pulse forever={true} duration={1000}>
                        <span className="has-text-primary" style={{ display: "flex" }}>
                          <FontAwesomeIcon icon={faHandPointRight} size="3x" />
                        </span>
                      </Pulse>
                    </div>
                    <div className="column">
                      <div className="label is-normal">
                        <label htmlFor="hauteur">Dimensions {pageProduit.pp.type === "simple" ? "de la fenêtre image" : "des fenêtres image"}</label>
                      </div>
                      <div className="field is-horizontal">
                        <div className="field-body">
                          <div className="field">
                            <div className="control is-flex is-flex-direction-row">
                              <div data-info="horizontal">
                                <input
                                  id="largeur"
                                  type="number"
                                  name="largeur"
                                  autoComplete="off"
                                  onChange={() => setShowSubmitDim(true)}
                                  ref={largeurInput}
                                  className="input input-number"
                                  min={0}
                                  step=".1"
                                  max={Math.max(hauteurMaxFen, largeurMaxFen).toFixed(1)}
                                  required
                                />
                              </div>
                              <span style={{ position: "relative", top: 5 }}>&nbsp;X&nbsp;</span>
                              <div data-info="vertical">
                                <input
                                  id="hauteur"
                                  type="number"
                                  name="hauteur"
                                  onChange={() => setShowSubmitDim(true)}
                                  autoComplete="off"
                                  className="input input-number"
                                  min={0}
                                  step="0.1"
                                  max={Math.max(hauteurMaxFen, largeurMaxFen).toFixed(1)}
                                  required
                                />
                              </div>
                              <span style={{ position: "relative", top: 5 }}>&nbsp;cm&nbsp;</span>
                              {apertures === 1 ? (
                                <span style={{ position: "relative", top: 5 }}>
                                  &nbsp;(max :{" "}
                                  {largeur > hauteur
                                    ? `${Math.max(largeurMaxFen / passe.ouvertures[1], hauteurMaxFen / passe.ouvertures[0]).toFixed(0)} x ${Math.min(
                                        largeurMaxFen / passe.ouvertures[1],
                                        hauteurMaxFen / passe.ouvertures[0]
                                      ).toFixed(0)}cm)`
                                    : `${Math.min(largeurMaxFen / passe.ouvertures[1], hauteurMaxFen / passe.ouvertures[0]).toFixed(0)} x ${Math.max(
                                        largeurMaxFen / passe.ouvertures[1],
                                        hauteurMaxFen / passe.ouvertures[0]
                                      ).toFixed(0)}cm)`}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {pageProduit.pp.type === "simple" && (
                    <>
                      <div className="label is-normal">
                        <label htmlFor="hauteur">Dimensions extérieures du passe-partout</label>
                      </div>
                      <div className="field is-horizontal">
                        <div className="field  mr-2">
                          <div className="control">
                            <div data-info="horizontal">
                              <input
                                className="input input-number"
                                type="number"
                                name="largeurExt"
                                onChange={() => setShowSubmitDim(true)}
                                autoComplete="off"
                                min={bordureMin * 2}
                                max={150}
                                required
                                step=".1"
                              />
                            </div>
                          </div>
                        </div>
                        <span style={{ position: "relative", top: 5 }}>X&nbsp;</span>
                        <div className="field  mr-2">
                          <div className="control">
                            <div data-info="vertical">
                              <input
                                className="input input-number"
                                type="number"
                                name="hauteurExt"
                                onChange={() => setShowSubmitDim(true)}
                                autoComplete="off"
                                min={bordureMin * 2}
                                max={150}
                                required
                                step=".1"
                              />
                            </div>
                          </div>
                        </div>
                        <span style={{ position: "relative", top: 5 }}>cm&nbsp;</span>
                        <span style={{ position: "relative", top: 5 }}>
                          &nbsp;(max : {(largeurMax / passe.ouvertures[1]).toFixed(0)} x {(hauteurMax / passe.ouvertures[0]).toFixed(0)}cm)
                        </span>
                      </div>
                    </>
                  )}
                  {pageProduit.type === "multivues" && (
                    <i>
                      Nous vous recommandons des dimensions extérieures de {dimSaisies.largeur * passe.ouvertures[0] + 4 * (passe.ouvertures[0] - 1) + 5 + 5} x{" "}
                      {dimSaisies.hauteur * passe.ouvertures[1] + 4 * (passe.ouvertures[1] - 1) + 5 + 5}
                      cm
                    </i>
                  )}
                  <br />
                  {showSubmitDim && (
                    <button type="submit" className="button is-primary mb-2" data-cy="valide-passe-product-dim">
                      Valider
                    </button>
                  )}
                  {displayErrorDim ? (
                    <>
                      {((dimSaisies.largeur < 1 && dimSaisies.largeur !== 0) || (dimSaisies.hauteur < 1 && dimSaisies.hauteur !== 0)) && (
                        <div className="notification is-danger">Les dimensions minimales est 1cm (0 est possible pour un passe-partout plein)</div>
                      )}
                      {Math.max(pageProduit.hauteur, pageProduit.largeur) > Math.max(largeurMaxFen, hauteurMaxFen) ||
                        Math.min(pageProduit.hauteur, pageProduit.largeur) > Math.min(largeurMaxFen, hauteurMaxFen) ||
                        Math.max(dimSaisies.largeur, dimSaisies.hauteur) > Math.max(largeurMaxFen, hauteurMaxFen) ||
                        (Math.min(dimSaisies.largeur, dimSaisies.hauteur) > Math.min(largeurMaxFen, hauteurMaxFen) && (
                          <div className="notification is-danger">
                            La dimension maximum de la fenêtre du passe partout sélectionné est supérieure à {largeurMaxFen}x{hauteurMaxFen}cm.
                          </div>
                        ))}
                    </>
                  ) : (
                    ""
                  )}

                  {(hauteur > hauteurMax || largeur > largeurMax) && !dimSaisies.default && !dimensionsSet && displayErrorDim ? (
                    <div className="notification is-danger">
                      La dimension maximum hors tout du passe partout sélectionné est supérieure à {largeurMax}x{hauteurMax}cm.
                      {largeur < 102 && hauteur < 152 && (
                        <>
                          seuls les passes partout , <Link href="/passe-partout/extra-blanc-ame-blanche-epaisseur-14mm-175/">extra blanc</Link> ,{" "}
                          <Link href="/passe-partout/blanc-casse-ame-blanche-epaisseur-14mm-176/">blanc cassé</Link> ,{" "}
                          <Link
                            href="
                        /passe-partout/noir-ame-blanche-epaisseur-14mm-179/"
                          >
                            noir
                          </Link>{" "}
                          et <Link href="/passe-partout/gris-clair-ame-blanche-epaisseur-14mm-177/">crème</Link> épaisseur 1.4mm âme blanche ne peuvent vous
                          être proposés
                        </>
                      )}
                    </div>
                  ) : hidden ? (
                    <div className="notification is-danger has-text-left">Configuration impossible car la marge est inférieure à {/*getBordureMin()*/}cm </div>
                  ) : (
                    ""
                  )}
                </form>
                {dimensionsSet && (
                  <>
                    <div className="label is-normal">
                      <b>Je confirme les marges intérieures du passe partout</b>
                    </div>
                    <div className="field has-addons mr-3 is-justify-content-center">
                      <p className="control">
                        <span className="button is-static">Haut</span>
                      </p>
                      <p className="control">
                        <input className="input input-number" type="number" value={dimSaisies.h} name="h" onChange={handleUpdate} step="0.01" />
                      </p>
                      <p className="control">
                        <span className="button is-static">cm</span>
                      </p>
                    </div>
                    <div className="is-flex is-flex-direction-row is-justify-content-center">
                      <div className="field has-addons mr-3">
                        <p className="control">
                          <span className="button is-static">Gauche</span>
                        </p>
                        <p className="control">
                          <input className="input input-number" type="number" value={dimSaisies.g} name="g" onChange={handleUpdate} step="0.01" />
                        </p>
                        <p className="control">
                          <span className="button is-static">cm</span>
                        </p>
                      </div>
                      <div className="field has-addons mr-3">
                        <p className="control">
                          <span className="button is-static">Droite</span>
                        </p>
                        <p className="control">
                          <input className="input input-number" type="number" value={dimSaisies.d} onChange={handleUpdate} name="d" step="0.01" />
                        </p>
                        <p className="control">
                          <span className="button is-static">cm</span>
                        </p>
                      </div>
                    </div>
                    <div className="field has-addons mr-3 is-justify-content-center">
                      <p className="control">
                        <span className="button is-static">Bas</span>
                      </p>
                      <p className="control">
                        <input className="input input-number" type="number" value={dimSaisies.b} name="b" onChange={handleUpdate} step="0.01" />
                      </p>
                      <p className="control">
                        <span className="button is-static">cm</span>
                      </p>
                    </div>
                    {!margesSet && (
                      <div className="is-flex is-flex-direction-row-reverse">
                        <button className="button is-primary mb-2" onClick={() => setMargesSet(true)} data-cy="valider-passe-marges-product">
                          Valider
                        </button>
                      </div>
                    )}
                    {margesSet && (
                      <>
                        {(Math.max(largeur, hauteur) > Math.max(largeurMax, hauteurMax) || Math.min(hauteur, largeur) > Math.min(hauteurMax, largeurMax)) && (
                          <div className="notification is-danger">
                            Les dimensions saisies sont supérieures aux maximales. (le passe partout ne doit pas être supérieur à {largeurMax}x{hauteurMax}cm.
                            Veuillez renseigner des fenêtres image de dimensions inférieures)
                          </div>
                        )}

                        <div className="label is-normal">
                          <b>Résumé</b>
                        </div>
                        <div className="field is-horizontal">
                          <div>
                            <u>{pp.pa_libelle.replace("+ Passe-partout", "+")}</u>
                            <br />
                            {dimSaisies.largeur === 0 && dimSaisies.hauteur === 0
                              ? "Sans ouverture"
                              : `Dimension d'une fenêtre image : ${dimSaisies.largeur}cm x ${dimSaisies.hauteur}cm`}
                            <br />
                            Dimensions extérieures :{" "}
                            {pageProduit.pp.type === "simple" ? `${dimSaisies.largeurExt}cm x ${dimSaisies.hauteurExt}cm` : `${largeur}cm x ${hauteur}cm`}
                            <br />
                            Marges : haut : {dimSaisies.h}cm, bas : {dimSaisies.b}cm, gauche: {dimSaisies.g}cm, droite : {dimSaisies.d}cm
                            <br />
                          </div>
                        </div>
                        {!dimSaisies.default &&
                        (dimSaisies.largeur === 0 || dimSaisies.largeur >= 1) &&
                        (dimSaisies.hauteur === 0 || dimSaisies.hauteur >= 1) &&
                        (Math.max(largeur, hauteur) > Math.max(largeurMax, hauteurMax) || Math.min(hauteur, largeur) > Math.min(hauteurMax, largeurMax)) ? (
                          ""
                        ) : hidden ? (
                          ""
                        ) : (
                          <div className="has-text-right">
                            {/* TODO : Gérer non connecté */}
                            <AjouterPanier product_selected={{ ...pp, type: "passe_partout" }} produits_annexes={pa} afteradd={() => reset()} />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

PassePartoutProductPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
}

export default PassePartoutProductPage
